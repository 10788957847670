<template>
  <div id="main">
    <div id="manu">
      <navigation />
    </div>
    <div id="contentBox">
      <div class="registervideoContainer"></div>
      <div class="w">
        <div class="video">
        <ul>
        <li v-for="(item, index) in filteredResults" :key="index">
          {{ item }}
        </li>
        </ul>
        <div class="card" ref="card">
          <div class="card-front">
        <img src="@/assets/cats_42kb.jpg" alt="cat">
        <div class="desc">
          <div class="text">原站免费VIP视频解析</div><br>
          <a href="#" class="link" target="_blank">声明：请勿相信视频中广告 | 若播放异常请刷新，或者更换接口尝试哦 | 解析地址均为互联网收集！！！</a>
        </div>
        <form @submit.prevent="submitForm">
          <input
            type="text"
            v-model="mediaURL"
            ref="mediaInput"
            id="media-url"
            placeholder="请输入视频网址，支持腾讯、优酷、爱奇艺等..."
          />
            <div class="apis">
            <input type="radio" id="api1" class="api" name="contact" value="https://jx.xmflv.com/?url=" checked>
            <label for="api1">线路1</label>

            <input type="radio" id="api2" class="api" name="contact" value="https://jx.playerjy.com/?url=">
            <label for="api2">线路2</label>

            <input type="radio" id="api3" class="api" name="contact" value="https://jx.nnxv.cn/tv.php?url=">
            <label for="api4">线路3</label>

            <input type="radio" id="api4" class="api" name="contact" value="https://jx.2s0.cn/player/?url=">
            <label for="api5">线路4</label>
          </div>
          
          <router-link to="#" class="viplay">使用方法</router-link>
          <button type="submit" class="play">播放</button>
        </form>
      </div>
            <div class="card-back">
              <iframe class="player" 
                      :src="playerSrc" 
                      frameborder="0" 
                      allowfullscreen></iframe>
              <div class="button-container">
                <button class="return" title="返回首页" @click="returnToFront">
                  <svg class="icon" viewBox="0 0 1024 1024" width="20" height="20">
                    <path d="M112.228037 296.934806c0 0 80.84119-125.866662 208.754464-193.404871s285.502429-48.095391 388.856355 17.396205 138.146337 120.750131 138.146337 120.750131l89.027639-51.16531c0 0 18.419512-9.209756 18.419512 12.279674s0 321.318146 0 321.318146 0 28.652574-21.48943 18.419512c-18.078751-8.609075-213.596749-120.784924-275.207969-156.176992-33.830503-15.226796-4.107551-27.595498-4.107551-27.595498l85.910649-49.541323c0 0-49.004087-61.923328-120.724549-94.717222-76.773548-40.359196-148.645458-45.144176-236.690724-11.608386-57.407478 21.86703-124.999922 77.853136-173.680645 160.560836C112.228037 296.934806 112.228037 296.934806 112.228037 296.934806zM910.406872 721.592552c0 0-80.84119 125.866662-208.754464 193.404871s-285.502429 48.095391-388.856355-17.396205-138.146337-120.750131-138.146337-120.750131l-89.027639 51.16531c0 0-18.419512 9.209756-18.419512-12.279674s0-321.318146 0-321.318146 0-28.652574 21.48943-18.419512c18.078751 8.609075 213.596749 120.784924 275.207969 156.176992 33.830503 15.226796 4.107551 27.595498 4.107551 27.595498l-85.910649 49.541323c0 0 49.004087 61.923328 120.724549 94.717222 76.773548 40.359196 148.645458 45.144176 236.690724 11.608386 57.407478-21.86703 124.999922-77.853136 173.680645-160.560836C910.406872 721.592552 910.406872 721.592552 910.406872 721.592552z" fill="#ffffff"></path>
                  </svg>
                </button>
                <button class="timer" title="定时关闭" @click="toggleSetTimer">
                  <svg class="icon" viewBox="0 0 1024 1024" width="20" height="20">
                    <path d="M512 854.016q123.989333 0 210.986667-88.021333t86.997333-212.010667-86.997333-210.986667-210.986667-86.997333-210.986667 86.997333-86.997333 210.986667 86.997333 212.010667 210.986667 88.021333zM811.989333 315.989333q84.010667 105.984 84.010667 237.994667 0 157.994667-112 271.018667t-272 112.981333-272-112.981333-112-271.018667 112-271.018667 272-112.981333q130.005333 0 240 86.016l59.989333-61.994667q32 25.984 59.989333 59.989333zM470.016 598.016l0-256 84.010667 0 0 256-84.010667 0zM640 41.984l0 86.016-256 0 0-86.016 256 0z" fill="#ffffff"></path>
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div class="set-timer" :class="{ 'show-set-timer': showTimerOptions }">
            <div class="title">定时关闭</div>
            <div class="select-minutes">
              <button class="set-timer-30" @click="setTimer(1800000)">30分钟</button>
              <button class="set-timer-60" @click="setTimer(3600000)">60分钟</button>
              <button class="set-timer-90" @click="setTimer(5400000)">90分钟</button>
              <button class="cancel-btn" @click="cancelTimer">取消定时器</button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="history" v-if="isLoggedIn && showHistory">
        <div>观看记录</div>
        <ul id="history">
          <li v-for="(url, index) in watchHistory" :key="index">
            <a :href="url">{{ url }}</a>
          </li>
        </ul>
      </div> -->
      <Pfooter/>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    keywords: {
      type: String,
      required: true
    }
  },
  watch: {
    keywords() {
      this.filterResults();
    }
  },
  data() {
    return {
      mediaURL: '',
      playerSrc: '',
      showTimerOptions: false,
      timer: null,
      delay: null,
      isLoggedIn: false,
      // watchHistory: [],
      showHistory: true,
    };
  },
  methods: {
    submitForm() {
      let api = document.querySelector('.api:checked').value;
      // 保存当前的 mediaURL 到 watchHistory 数组
      if (this.mediaURL) {
        this.watchHistory.push(this.mediaURL);
        // 同时保存到 localStorage 以便在页面刷新时还能获取到
        localStorage.setItem('watchHistory', JSON.stringify(this.watchHistory));
      }
      // 隐藏观看记录
      this.showHistory = false;

      this.$refs.card.classList.add('turn-to-back');
      this.delay = setTimeout(() => {
        this.playerSrc = api + this.mediaURL;
        this.setHeaders();
      }, 800);
    },
    returnToFront() {
      this.playerSrc = '';
      this.$refs.card.classList.remove('turn-to-back');
      this.mediaURL = '';

      //显示记录
      this.showHistory = true;

      clearTimeout(this.delay);
    },
    toggleSetTimer() {
      this.showTimerOptions = !this.showTimerOptions;
    },
    setTimer(duration) {
      this.showTimerOptions = false;
      this.timer = setTimeout(() => {
        this.returnToFront();
      }, duration);
    },
    cancelTimer() {
      this.showTimerOptions = false;
      if (this.timer) {
        clearTimeout(this.timer);
      }
    },
  },
  mounted() {
    this.isLoggedIn = localStorage.getItem("isLoggedIn") === 'true';

    // 恢复保存的观看记录
    const savedHistory = localStorage.getItem('watchHistory');
    if (savedHistory) {
      this.watchHistory = JSON.parse(savedHistory);
    }
  }
}
</script>

<style scoped>
/* 版心 */
.w {
	width: 1700px;
	margin: 0 auto;
}
img{
  width: 100%;
  height: 100%;
  display: block;
}
#contentBox {
  width: 100%;
  height: 100%;
}
.registervideoContainer {
  background-size: cover;
  background-repeat: no-repeat;
  /* //保证盒子始终在窗口中心 */
  background-position: center;
  /* //避免窗口周围留白 */
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(../assets/2.jpg);
  /* 增加虚化效果，参数可以根据需要调整 */
  /* filter: blur(6px); */
}
.video{
  margin-left: 40%;
}
body {
  margin: 0;
  padding: 0;
  -webkit-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  perspective: 1200px;
  background-color: var(--theme-color);
}
.card {
  position: absolute;
  transform-style: preserve-3d;
  width: 28rem;
  height: 34rem;
  border-radius: 30px;
  box-shadow: 20px 95px 40px var(--shadow-right-bottom-color),
              -20px 95px 40px var(--shadow-left-top-color);
  transform: rotateY(0deg);
  transition: 1s cubic-bezier(0.83, 0, 0.17, 1);
  margin-top: 10vh;
  margin-bottom: 55%;
}

.card.turn-to-back {
  transform: rotateY(180deg);
  box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
              20px -20px 20px var(--shadow-left-top-color);
  width: 80%;
}

.card-front, .card-back {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 30px;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: var(--theme-color);
  overflow: hidden;
}

/* 正面 */
.card-front {
  position: absolute;
}

.card-back {
  transform-style: preserve-3d;
  transform: rotateY(180deg);
}

.card-front img {
  width: 100%;
}

.card-front .desc {
  width: 80%;
  padding: 40px 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.card-front .desc .text {
  margin-left: 5rem;
  margin-top: -3rem;
  font-size: 20px;
  color: var(--shadow-right-bottom-color);
}

.card-front .desc .link {
  color: #d9d9d9;
  height: 5vh;
  width: 40vh;
  margin-left: 1rem;
  font-size: 15px;
}

.card-front form {
  flex: 1;
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.card-front form #media-url {
  margin-top: -3rem;
  line-height: 30px;
  outline: none;
  border: 0;
  border-radius: 25px;
  font-size: 17px;
  box-sizing: border-box;
  box-shadow: inset 2px 2px 4px var(--input-left-top-color), 
              inset -2px -2px 4px var(--input-right-bottom-color);
}

.card-front form #media-url:hover {
  background: linear-gradient(145deg, #0f71c0, #ffffff);
}

.card-front form .apis {
  width: 120%;
  line-height: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: var(--shadow-right-bottom-color);
}

.card-front form .play,
.card-front form .viplay {
  width: 40%;
  line-height: 30px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  outline: none;
  border: 0;
  border-radius: 25px;
  background: linear-gradient(145deg, #838383, #6e6e6e);
  box-shadow:  2px 2px 8px #686868, 
                -2px -2px 8px #8c8c8c;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
}
.card-front form .play:hover,
.card-front form .viplay:hover {
  background: #7a7a7a;
}

.card-front form .play:active,
.card-front form .viplay:active {
  background: linear-gradient(145deg, #6e6e6e, #838383);
  box-shadow:  2px 2px 4px #686868, 
                -2px -2px 4px #8c8c8c;
}

/* 背面 */
.card .card-back {
  display: flex;
  flex-direction: column;
}

.card .card-back .player {
  flex: 9;
  width: 100%;
}

.card .card-back .button-container {
  flex: 1;
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.card .card-back .return,
.card .card-back .timer {
  outline: none;
  border: 0;
  padding: 6px 6px 4px 6px;
  border-radius: 999px;
  background: var(--return-button-color);
  box-shadow:  2px 2px 4px var(--shadow-right-bottom-color), 
                -2px -2px 4px var(--shadow-left-top-color);
  cursor: pointer;
}

.card .card-back .button-container .return {
  margin: auto;
  transform: translateX(50%);
}

.card .card-back .return:hover,
.card .card-back .timer:hover {
  background: linear-gradient(145deg, #e6a39e, #ffc2bb);
}

.card .card-back .return:active,
.card .card-back .timer:active {
  box-shadow: inset 2px 2px 4px var(--shadow-right-bottom-color), 
            inset -2px -2px 4px var(--shadow-left-top-color);
}
.card.turn-to-back {
  height: 70vh;
  width: 160vh;
  margin-left: -80vh;
  top: 2vh;
}
.copyright {
  position: fixed;
  bottom: 6px;
}

.copyright :not(.author-link){
  color: #fff;
}

.copyright p {
  margin: 0;
}

.copyright .cprt {
  display: none;
}

.copyright .author-link {
  display: inline-block;
  font-weight: bolder;
  padding: 0 4px;
  background-color: rgba(255, 255, 255, .6);
  border-radius: 4px;
  transition: .3s ease-in-out;
}

.set-timer {
  opacity: 0;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: .6s;
  z-index: -1;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;

  background-color: var(--theme-color);
}

.set-timer .title {
  color: var(--link-color);
  margin: 10px 0;
  font-size: 22px;
}

.set-timer .select-minutes {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.set-timer .select-minutes button {
  min-width: 130px;
  height: 40px;
  font-size: 18px;
  color: gray;
  background-color: var(--theme-color);
  border: none;
  border-radius: 4px;
  transition: .3s;
}

.set-timer .select-minutes button:hover {
  background-color: var(--shadow-left-top-color);
  color: var(--input-right-bottom-color);
  transform: scale(1.1);
}

.show-set-timer {
  opacity: 1;
  z-index: 100;
}
/*  */
.history{
  margin-top: 5%;
  margin-left: 65%;
  color: var(--input-right-bottom-color);
}
#history {
  width: 95% !important;
  white-space: pre-line !important;
}

#history a {
  width: 95% !important;
}
/* 横向时画面比例 */
/* iPhone 6 & 横屏*/
@media screen and (max-width: 667px) and (orientation: landscape) {
  .card.turn-to-back {
    height: 94vh;
  }

  .card .card-back .player {
    flex: 8.5;
  }

  .card .card-back .button-container {
    flex: 1.5;
  }

  footer {
    display: none;
  }
}

/* iPhone 12 Pro Max & 横屏*/
@media screen and (min-width: 668px) and (max-width: 926px) and (orientation: landscape) {
  .card.turn-to-back {
    width: 62.8%;
    height: 90vh;
  }

  .card .card-back .player {
    flex: 8.5;
  }

  .card .card-back .button-container {
    flex: 1.5;
  }
}

/* iPad及以上 & 横屏*/
@media screen and (min-width: 927px) {
  .card.turn-to-back {
    height: 76vh;
    width: 152vh;
    margin-left: -57vh;
    top: -2vh;
  }

  .copyright .cprt {
    display: inline;
  }

  .copyright .author-link:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
  }

  .copyright .author-link:hover {
    transform: scale(1.05);
    box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, .1);
  }
}
/* dark mode */
@media (prefers-color-scheme: dark) {
  :root {
    --link-color: #999999;
    --theme-color: #000000;
    --shadow-right-bottom-color: #0f0f0f;
    --shadow-left-top-color: #151515;
    --input-right-bottom-color: #6d6d6d;
    --input-left-top-color: #939393;
    --return-button-color: #E43F5A;
  }

  .card-front, .card-back {
    background-color: #1a1a1d;
  }

  .card-front img {
    opacity: .8;
  }

  .turn-to-back .card-front img {
    opacity: 1;
  }

  .card-front .desc .text,
  .card-front form .apis,
  .card-front form #media-url {
    color: #d7d7d7;
  }

  .card-front form #media-url {
    width: 27rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }

  .card-front form #media-url:hover {
    background: linear-gradient(145deg, #2a9b8c, #fef7ff);
  }

  .card-front form .play,
  .card-front form .viplay {
    background: #E43F5A;
    box-shadow: none;
  }

  .copyright .author-link {
    color:  rgba(228, 63, 90, .8);
    background-color: rgba(255, 255, 255, .8);
  }
}
@media screen and (max-width: 1600px) {
  .w {
    max-width: 1400px;
  }
  .registervideoContainer {
    margin-left: -0%;
  }
  .video {
    margin-left: 16vh;
  }
  .card {
    position: relative;
    width: calc(25% - 0px);
    margin-left: 55vh;
    margin-top: -54vh;
    margin-bottom: 14vh;
  }
  .card-front .desc .text {
    margin-left: 2rem;
    margin-top: -3rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 19rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -17.5vh;
    top: -5vh;
    transform: rotateY(180deg);
    box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color);
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 172vh;
    margin-left: -140vh;
    margin-top: 65vh;
  }
}
/* 桌面客户端 */
@media screen and (max-width: 1400px) {
  .w{
    max-width: 1200px;
  }
  .video {
    margin-left: 16vh;
  }
  .card {
    position: relative;
    width: calc(40% - 100px);
    margin-left: 50vh;
    margin-top: -54vh;
    margin-bottom: 14vh;
  }
  .card-front .desc .text {
    margin-left: 2rem;
    margin-top: -3rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 19rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -17.5vh;
    top: -5vh;
    transform: rotateY(180deg);
    box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color);
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 146vh;
    margin-left: -116vh;
    margin-top: 65vh;
  }
} 
/* 平板 */
@media screen and (max-width: 1200px) {
  .w{
    max-width: 1000px;
  }
  .video {
    margin-left: 16vh;
  }
  .card {
    position: relative;
    width: calc(40% - -40px);
    margin-left: 22vh;
    margin-top: -68vh;
    margin-bottom: 14vh;
  }
  .card-front .desc .text {
    margin-left: 2rem;
    margin-top: -3rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 19rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card-front, 
  .card-back {
    background-color: #1a1a1d;
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -17.5vh;
    top: 2vh;
    transform: rotateY(180deg);
    box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color);
  }
  .card-front form .apis {
    width: 125%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 120vh;
    margin-left: -90vh;
    margin-top: 70vh;
  }
}
/* 手机尺寸 */
@media screen and (max-width: 1000px) {
  .w{
    max-width: 100%;
  }
  .video {
    margin-top: 8vh;
    margin-left: 21vh;
  }
  .card {
    position: relative;
    width: calc(48% - 5px);
    margin-left: 15vh;
    margin-top: -55vh;
  }
  .card-front .desc .text {
    margin-left: 4rem;
    margin-top: -3rem;
    font-size: 15px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 17rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: 22.5vh;
    top: -5vh;
    transform: rotateY(180deg);
    box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color);
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 73vh;
    margin-left: -11vh;
  }
}
@media screen and (max-width: 550px) {
  .w{
    max-width: 100%;
  }
  .video {
    margin-top: 8vh;
    margin-left: 16vh;
  }
  .card {
    position: relative;
    width: calc(100% - -60px);
    margin-left: -12vh;
    margin-top: -55vh;
    margin-bottom: 50%;
  }
  .card-front .desc .text {
    margin-left: 2rem;
    margin-top: -3rem;
    font-size: 20px;
    color: var(--shadow-right-bottom-color);
  }
  .card-front .desc .link {
    color: #d9d9d9;
    height: 9vh;
    width: 55vh;
    margin-left: 1rem;
    font-size: 12px;
  }
  .card-front form #media-url {
    width: 19rem;
    font-size: 12px;
    background-color: rgba(10, 79, 99, 0.459);
  }
  .card.turn-to-back {
    height: 70vh;
    width: 45vh;
    margin-left: -17.5vh;
    top: -5vh;
    transform: rotateY(180deg);
    box-shadow: -20px 20px 20px var(--shadow-right-bottom-color),
                20px -20px 20px var(--shadow-left-top-color);
  }
  .card-front form .apis {
    width: 153%;
    line-height: 30px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--shadow-right-bottom-color);
  }
  /* 背面 */
  .card-back { 
    width: 40vh;
    margin-left: 0vh;
  }
}
</style>